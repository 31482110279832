.contactus {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.contactus-hero {
  background-color: #05172d;
  background-position: center center;
  height: 200px;
  color: white;
  font-weight: bold;
  padding: 1rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.contactus-tag {
  padding: 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.5rem;
}
.contactus-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1.5rem;
  padding: 0rem;
  min-width: 200px;
}

@media (max-width: 768px) {
  .mobile-menu-icon {
    display: flex;
  }

  .nav-menu {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #f1f1f1;
    width: 100%;
  }

  .nav-menu.open {
    display: flex;
  }

  .intro {
    height: 400px;
  }
  .intro div:first-child {
    font-size: 1.3rem;
  }
  .intro div:nth-child(2) {
    font-size: 2rem;
  }
  .intro div:nth-child(3) {
    font-size: 1.1rem;
    margin-bottom: 0;
  }
  .intro div:nth-child(4) {
    font-size: 1rem;
  }
  .sermons iframe {
    width: 360px;
    height: 240px;
    border: none;
  }
  .contactus-tag {
    font-size: 1rem;
  }
  .ministry-details-name {
    height: 100px;
  }
  .ministry-details-image {
    width: 90%;
    margin: 2rem;
  }
  .contactus-hero {
    height: 100px;
  }
  /* .sermons iframe {
    width: 90%;
    height: 100%;
  } */
}
