body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
}

#root {
  display: flex;
  flex: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Intro */
.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  background-image: url('images/nmz_bg2.jpg');
  background-size: cover;
  background-position: center center;
  height: 400px;
  padding: 1rem;
  text-align: center;
}

.intro a {
  text-decoration: none;
  color: white;
}
.intro div:first-child {
  color: rgb(233, 32, 79);
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 2rem 2rem 1rem 1rem;
  /* text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.1); */
}
.intro div:nth-child(2) {
  font-size: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0rem 2rem 1rem 1rem;
}
.intro div:nth-child(3) {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0rem 2rem 1rem 1rem;
}
.intro div:nth-child(4) {
  font-size: 1.3rem;
  /* font-weight: bold; */
  margin: 2rem 2rem 1rem 1rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}
/*************** Intro ********************/

/*************** Sermons ********************/
.sermons {
  /* min-height: 200px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  text-align: center;
}
.sermons div {
  font-size: 2rem;
  font-weight: bold;
  margin: 1rem;
}

/* .sermons iframe {
  width: 640px;
  height: 400px;
  border: none;
} */
/*************** Sermons ********************/
/*************** Events ********************/
.events {
  background-color: rgba(241, 241, 241, 0.87);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  text-align: center;
}
.event-header {
  font-size: 2rem;
  font-weight: bold;
  margin: 1rem;
}
.event-title {
  font-weight: bold;
  font-size: 1.3rem;
  text-decoration: underline;
  margin: 1rem 1rem 0.5rem 0rem;
}
.event-subtitle {
  font-weight: bold;
  font-size: 1rem;
  color: rgb(233, 32, 79);
}
.event-time {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 1rem;
  margin: 1rem;
}
.event-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.services-hero {
  background-image: url('images/worship.jpg');
  background-size: cover;
  background-position: center center;
  height: 200px;
  color: white;
  font-weight: bold;
  padding: 1rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.services-tag {
  padding: 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.5rem;
}
.services-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1.5rem;
  padding: 3rem;
  min-width: 200px;
}

/*************** Ministires ********************/
.ministries {
  /* background-color: rgba(0, 0, 0, 0.1); */
  /* padding: 1rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 1rem; */
  text-align: center;
}
.ministry-header {
  font-size: 2rem;
  font-weight: bold;
  margin: 1rem;
}
.ministry-items {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: center;
  justify-content: space-evenly;
  /* width: 1800px; */
}
.ministry-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ministry-details-name {
  background-color: rgba(7, 2, 43, 0.86);
  height: 200px;
  color: white;
  font-weight: bold;
  padding: 1rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ministry-details-image {
  width: 75%;
  margin: 2rem;
}
.banner {
  padding: 1rem;
  background-color: gray;
}
/* Add CSS styles for the hamburger menu */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  margin-right: 20px;
}

.mobile-menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-left: 20px;
}

.mobile-menu-icon span {
  width: 30px;
  height: 4px;
  background-color: #000;
  margin-bottom: 4px;
  transition: all 0.3s ease;
}

.nav-menu {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.nav-menu li {
  margin-right: 15px;
}

.nav-menu li:last-child {
  margin-right: 0;
}

.nav-menu li a {
  text-decoration: none;
  color: #000;
  font-weight: bold;
}
.elementor-icon-list-items {
  list-style-type: circle;
}
.elementor-icon-list-item {
  list-style: circle;
}
.elementor-heading-title {
  color: #bf0a30;
  font-size: 26px;
  font-weight: 600;
  line-height: 38px;
}
.clickable-card-image {
  /* cursor: pointer; */
}

.clickable-card-image:hover {
  /* opacity: 0.75; */
}
@media (max-width: 768px) {
  .mobile-menu-icon {
    display: flex;
  }

  .nav-menu {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #f1f1f1;
    width: 100%;
  }

  .nav-menu.open {
    display: flex;
  }

  .intro {
    height: 400px;
  }
  .intro div:first-child {
    font-size: 1.3rem;
  }
  .intro div:nth-child(2) {
    font-size: 2rem;
  }
  .intro div:nth-child(3) {
    font-size: 1.1rem;
    margin-bottom: 0;
  }
  .intro div:nth-child(4) {
    font-size: 1rem;
  }
  .sermons iframe {
    width: 360px;
    height: 240px;
    border: none;
  }
  .services-tag {
    font-size: 1rem;
  }
  .ministry-details-name {
    height: 100px;
  }
  .ministry-details-image {
    width: 90%;
    margin: 2rem;
  }
  .services-hero {
    height: 100px;
  }
  /* .sermons iframe {
    width: 90%;
    height: 100%;
  } */
}

.donate-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  background-image: url('images/offering.jpg');
  background-size: cover;
  background-position: center center;
  min-height: 400px;
  padding: 1rem;
  text-align: center;
}

@media print {
  /* Hide elements that you don't want to print */
  .no-print {
    display: none;
  }

  /* body *:not(.mental-table) {
    display: none !important;
  } */
}

.iframe-container {
  overflow: hidden;
  width: 50%;
  height: 500px;
  /* padding-top: 56.25%; */
  /* background-color: #bf0a30; */
}

.iframe-container iframe {
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
  height: 100%;
}

/* Tablet */
@media (max-width: 780px) {
  .iframe-container iframe {
    width: 100px;
  }
  .iframe-container {
    width: 300px;
    height: 100%;
  }
}
